@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@300;400;500;600;700&family=Passion+One:wght@400;700;900&display=swap');

/*------------------------------------------------------*/
/* Frontend                                             */
/*------------------------------------------------------*/

.SOS {
  text-align: center;
  width: 100%;
  min-height: 100vh;
  font-family: 'Catamaran', sans-serif;
  font-weight: lighter;
  font-size: 15px;
  background-color: #6B2929;
  color: #FFFFFF;
  padding: 0 5% 10px 5%;
  box-sizing: border-box;
}

::selection {
  background-color: #FFFFFF;
  color: #6B2929;
}

.SOS.dark {
  background-color: #2a0d0d;
  color: #999999;
}

.SOS.dark ::selection {
  background-color: #999999;
  color: #000000;
}

.SOS.red {
  background-color: #6B2929;
  color: #FFFFFF;
}

.SOS.success,
.SOS.success.grey,
.SOS.success.dark {
  animation: success-background 2s ease;
}

.SOS-header {
  padding: 10px 0 0 0;
}

.SOS-header-logo {
  width: 150px;
  height: auto;
}

.dark .SOS-header-logo {
  opacity: 0.6;
}

.SOS-header-title {
  font-family: 'Passion One', cursive;
  font-weight: normal;
  font-size: 3em;
  margin: 0;
  padding: 0 30px;
  box-sizing: border-box;
  word-wrap: break-word;
  position: relative;
  width: 100%;
}

.SOS-back-button {
  background: transparent;
  font-family: 'Passion One', cursive;
  font-weight: normal;
  color: inherit;
  border: 0;
  font-size: 1em;
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.SOS-back-button:hover,
.SOS-back-button:focus {
  opacity: 0.7;
}

.SOS-content {
  text-align: left;
  font-size: 1em;
  margin-top: 10px;
}

.SOS-content p {
  line-height: 1.4;
  text-align: left;
}

.SOS-content > p:first-child {
  margin-top: 0;
}

.SOS-content p.error {
  color: #FF0000;
  font-weight: bold;
}

.SOS-content h1 {
  font-family: 'Passion One', cursive;
  font-weight: normal;
  text-align: center;
}

.SOS-content h2 {
  font-family: 'Passion One', cursive;
  font-weight: normal;
  font-size: 1.8em;
  text-align: center;
}

.SOS-content img {
  width: 100%;
  height: auto;
}

.SOS-content ul {
  padding-left: 20px;
  line-height: 1.5;
}

.SOS-content ul li {
  margin: 5px 0;
}

.SOS-content label {
  width: 100%;
}

.SOS-content .center,
.SOS-content a.center {
  text-align: center;
}

.dark-mode-switch.dark {
  display: none;
}

a {
  color: #FFFFFF;
}

.SOS.dark a {
  color: #999999;
}

a:hover,
a:focus {
  opacity: 0.7;
  cursor: pointer;
}

.SOS-content-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 10px;
  margin: 30px auto;
}

.SOS-content-grid * {
  justify-self: center;
}

.SOS-content-grid *:nth-child(odd):last-child {
  grid-column: 1/3;
}

.SOS-assistant-pages .hide {
  display: none;
}

.SOS-footer {
  font-size: 0.9em;
  opacity: 0.8;
  padding: 0 20px;
  min-height: 1em;
}

.center {
  text-align: center;
}

.loading-square,
.loading-round {
  visibility: hidden;
}

.loading-square::before {
  visibility: visible;
  content: '';
  width: 100%;
  height: 50px;
  display: block;
  background-color: #FFFFFF33;
  animation: continous-fade 2s ease infinite;
}

.SOS.grey .loading-square::before {
  background-color: #00000066;
}

.loading-round::before {
  visibility: visible;
  content: '';
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin: 20px auto;
  display: block;
  background-color: #FFFFFF33;
  animation: continous-fade 2s ease infinite;
}

.SOS.grey .loading-round::before {
  background-color: #00000066;
}

@media only screen and (min-width: 500px) {
  .SOS {
    padding-left: 10%;
    padding-right: 10%;
  }
}

@media only screen and (min-width: 750px) {
  .SOS {
    padding-left: 15%;
    padding-right: 15%;
    font-size: 16px;
  }

  .SOS-header-title {
    padding: 0 40px;
    margin: 10px 0;
  }

  .SOS-header {
    padding: 20px 0 0 0;
  }

  .SOS-content-grid {
    row-gap: 30px;
  }
}

@media only screen and (min-width: 1000px) {
  .SOS {
    padding-left: 25%;
    padding-right: 25%;
  }

  .SOS-back-button {
    left: 10px;
  }

  .SOS-header-title {
    padding: 0 50px;
  }
}

@media only screen and (min-width: 1200px) {
  .SOS {
    padding-left: 30%;
    padding-right: 30%;
    font-size: 18px;
  }
}

/*------------------------------------------------------*/
/* Backend                                              */
/*------------------------------------------------------*/

.SOS.grey {
  background-color: #333333;
  color: #FFFFFF;
}

.SOS.grey ::selection {
  background-color: #FFFFFF;
  color: #333333;
}

.SOS-link-button.SOS-header-logout-button {
  display: inline-block;
  text-align: center;
  margin: 5px auto;
  font-size: 1em;
  font-family: 'Catamaran', sans-serif;
  font-weight: lighter;
  color: inherit;
  background: transparent;
  border: 0;
  position: absolute;
  top: 18px;
  right: 18px;
  width: auto;
  text-decoration: none;
}

.SOS-header-logout-button:hover,
.SOS-header-logout-button:focus {
  opacity: 0.7;
  text-decoration: underline;
}

.SOS-link-button.SOS-header-privacy-button {
  display: inline-block;
  text-align: center;
  margin: 5px auto;
  font-size: 1.7em;
  font-family: 'Catamaran', sans-serif;
  font-weight: lighter;
  color: inherit;
  background: transparent;
  border: 0;
  position: absolute;
  top: 10px;
  right: 15px;
  width: auto;
  text-decoration: none;
  filter: saturate(0) brightness(100);
}

.SOS.dark .SOS-link-button.SOS-header-privacy-button {
  filter: saturate(0) brightness(1);
}

.SOS-header-privacy-button:hover,
.SOS-header-privacy-button:focus {
  opacity: 0.7;
  text-decoration: underline;
}

.SOS-backend-person {
  display: flex;
  gap: 20px;
  margin: 40px auto;
}

.SOS-backend-person.error {
  outline-color: #FF0000;
  outline-style: solid;
  outline-width: 3px;
  outline-offset: 5px;
}

.SOS-backend-person > * {
  align-self: center;
}

.SOS-backend-delete-person {
  padding: 0;
}

.SOS-backend-delete-person > .SOS-link-button {
  padding: 0;
}

.SOS-backend-person-list {
  margin: 30px 0 50px;
}

.SOS-file-upload:hover > .SOS-image:not(.loading),
.SOS-file-upload:focus > .SOS-image:not(.loading),
.SOS.grey .SOS-file-upload:hover > .SOS-image:not(.loading),
.SOS.grey .SOS-file-upload:focus > .SOS-image:not(.loading) {
  background-color: #FFFFFF55;
  cursor: pointer;
}

.grecaptcha-badge {
  display: none !important;
}

/*------------------------------------------------------*/
/* Animation                                            */
/*------------------------------------------------------*/

@keyframes continous-fade {
  0% { opacity: 1; }
  50% { opacity: 0.4; }
  100% { opacity: 1; }
}

@keyframes success-background {
  50% { background-color: #008800; }
}
