.SOS-button {
  display: block;
  width: 100%;
  margin: 10px auto;
  font-size: 1.6em;
  font-family: 'Passion One', cursive;
  font-weight: normal;
  line-height: 1.8em;
  color: inherit;
  background-color: #FFFFFF33;
  border: 0;
  cursor: pointer;
}

.SOS.grey .SOS-button {
  background-color: #00000066;
}

.SOS-button:disabled {
  opacity: 0.5;
  cursor: default;
}

.SOS-button.red-button,
.SOS.grey .SOS-button.red-button {
  background-color: #FF000044;
}

.SOS-button:hover:not(:disabled),
.SOS-button:focus:not(:disabled),
.SOS.grey .SOS-button:hover:not(:disabled),
.SOS.grey .SOS-button:focus:not(:disabled) {
  background-color: #FFFFFF55;
}

.SOS-button.red-button:hover,
.SOS-button.red-button:focus,
.SOS.grey .SOS-button.red-button:hover,
.SOS.grey .SOS-button.red-button:focus {
  background-color: #FF000088;
}

.SOS-link-button {
  display: inline-block;
  margin: 5px auto;
  font-size: 1em;
  font-family: 'Catamaran', sans-serif;
  font-weight: lighter;
  color: inherit;
  background: transparent;
  border: 0;
  cursor: pointer;
  text-decoration: underline;
}

.SOS-link-button.red-button {
  color: #FF0000;
}

.SOS-link-button.center {
  text-align: center;
  display: block;
}

.SOS-link-button.no-underline {
  text-decoration: none;
}

.SOS-link-button.no-margin {
  margin: 0;
  padding: 0;
}

.SOS-link-button:hover,
.SOS-link-button:focus {
  opacity: 0.7;
}

.SOS-img-button {
  color: inherit;
  background: transparent;
  border: 0;
  font-family: 'Passion One', cursive;
  font-size: inherit;
  min-width: 100px;
  display: grid;
  padding: 0;
  grid-template-rows: 100px auto;
  cursor: pointer;
}

.SOS-img-button-icon {
  border-radius: 50%;
  width: 100px;
  height: 100%;
  background-color: #FFFFFF33;
  background-size: 70%;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  user-select: none;
}

.SOS.grey .SOS-img-button-icon {
  background-color: #00000066;
}

.SOS.dark .SOS-img-button-icon {
  filter: saturate(0.7);
  opacity: 0.7;
  background-color: #FFFFFF55;
}

.SOS-img-button:hover .SOS-img-button-icon,
.SOS-img-button:focus .SOS-img-button-icon {
  background-color: #FFFFFF55;
}

.SOS.dark .SOS-img-button:hover .SOS-img-button-icon,
.SOS.dark .SOS-img-button:focus .SOS-img-button-icon {
  background-color: #FFFFFF77;
}

.SOS-img-button p {
  font-size: 1.2em;
  text-align: center;
  margin-top: 10px;
  word-wrap: break-word;
  width: 100%;
}

.SOS-img-button:hover .SOS-img-button-icon,
.SOS-img-button:focus .SOS-img-button-icon {
  opacity: 0.7;
}

.SOS-large-img-button-subtitle {
  font-size: 0.8em;
  font-family: 'Catamaran', sans-serif;
  font-weight: lighter;
}

.SOS-image {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background-color: #FFFFFF33;
  background-size: 70%;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  text-align: center;
  margin: 0 auto;
  user-select: none;
}

.SOS-image.error {
  border: 3px solid #FF0000;
}

.SOS.grey .SOS-image {
  background-color: #00000066;
}

.SOS.dark .SOS-image {
  filter: saturate(0.7);
}

.SOS-timer {
  font-family: 'Passion One', cursive;
  font-weight: normal;
  text-align: center;
  font-size: 2em;
}

.SOS-timer > p {
  margin-top: 5px;
  text-align: center;
}

.SOS-timer-count {
  font-size: 3em;
}

.SOS-search-input {
  width: 100%;
  height: auto;
}

.SOS-trust-person-search ul,
.SOS-orga-person-search ul {
  padding-left: 30px;
}

.SOS-orga-person-search .SOS-link-button,
.SOS-trust-person-search .SOS-link-button {
  font-family: 'Passion One', cursive;
  font-size: 1.5em;
}

.SOS-input {
  width: 100%;
  padding: 8px 12px;
  background-color: #FFFFFF33;
  border: 3px solid transparent;
  box-sizing: border-box;
  color: #FFFFFF;
  font-size: 1.1em;
  font-family: 'Catamaran', sans-serif;
  font-weight: lighter;
  box-sizing: border-box;
  transition: border 0.5s;
  -webkit-appearance: none;
  text-align: left;
}

.SOS-input.error {
  border: 3px solid red;
  transition: border 0.5s;
}

.SOS-input.error::after {
  color: #FF0000;
  content: 'Bitte überprüfe deine Eingabe';
}

.SOS.grey .SOS-input {
  background-color: #00000066;
}

.SOS-input::placeholder {
  color: #FFFFFF88;
  font-size: 1.1em;
  font-family: 'Catamaran', sans-serif;
  font-weight: lighter;
}

/* for ios*/
.SOS-input::-webkit-date-and-time-value {
  text-align: left;
}

.SOS-textarea {
  width: 100%;
  height: 200px;
  padding: 8px 12px;
  background-color: #FFFFFF33;
  border: 0;
  box-sizing: border-box;
  resize: none;
  color: #FFFFFF;
  font-size: 1.1em;
  font-family: 'Catamaran', sans-serif;
  font-weight: lighter;
}

.SOS.grey .SOS-textarea {
  background-color: #00000066;
}

.SOS-textarea::placeholder {
  color: #FFFFFF88;
  font-size: 1.1em;
  font-family: 'Catamaran', sans-serif;
  font-weight: lighter;
}

.SOS-large-button {
  line-height: 1;
  padding: 13px 6px;
}

.SOS-large-button-subtitle {
  font-size: 0.6em;
  font-family: 'Catamaran', sans-serif;
  font-weight: lighter;
}

.SOS-select {
  display: block;
  width: 100%;
  margin: 10px auto;
  font-size: 1.7em;
  font-family: 'Passion One', cursive;
  font-weight: normal;
  height: 1.9em;
  text-align: center;
  color: inherit;
  background-color: #FFFFFF33;
  border: 0;
}

.SOS-select option {
  width: 100%;
  margin: 10px auto;
  font-family: 'Passion One', cursive;
  font-weight: normal;
  text-align: center;
  color: inherit;
  border: 0;
  background-color: #895454;
}

.SOS.dark .SOS-select option {
  background-color: #333333;
}

.SOS-select:hover,
.SOS-select:focus {
  background-color: #FFFFFF55;
}

.hidden {
  display: none;
}

h2.SOS-collapse-heading {
  text-align: left;
  cursor: pointer;
  margin: 10px 0;
}

.SOS-collapse-heading::before {
  content: '';
  display: inline-block;
  position: relative;
  height: 10px;
  width: 10px;
  border-bottom: 4px solid #FFFFFF;
  border-right: 4px solid #FFFFFF;
  display: inline-block;
  transform-origin: center center;
  transform: rotate(45deg) translate(-4px, -5px);
  margin-right: 10px;
  transition: transform 0.5s ease;
}

.SOS.dark .SOS-collapse-heading::before {
  border-color: #999999;
}

.SOS-collapse.collapsed .SOS-collapse-heading::before {
  transform: rotate(-45deg) translate(-4px, -5px);
  transition: transform 0.5s ease;
}

.SOS-collapse-content {
  height: auto;
  overflow: hidden;
  transition: max-height 1s ease;
}

.SOS-collapse.collapsed .SOS-collapse-content {
  max-height: 0;
  transition: max-height 1s ease;
}

.SOS-message-box {
  position: fixed;
  bottom: 5%;
  left: 0;
  right: 0;
  width: 80%;
  margin: 0 auto;
  background-color: #000000BB;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  display: grid;
  height: 0;
  box-sizing: border-box;
  grid-template-columns: auto 10%;
  grid-template-rows: 100%;
  box-shadow: 0px 0px 20px #000000BB;
  text-align: left;
  animation: fade-out-message 0.7s ease backwards;
  overflow: hidden;
  z-index: 1000;
}

.SOS-message-box.red {
  background-color: #560C0CBB;
}

.SOS-message-box.green {
  background-color: #008800BB;
}

.SOS-message-box.show {
  height: auto;
  animation: show-message 0.7s ease forwards;
}

.SOS-message-box p,
.SOS-message-box h3 {
  margin: 0;
}

.SOS-message-box-content {
  margin: 10px;
}

.SOS-message-box-close {
  border-left: 1px solid #FFFFFF;
  text-align: center;
  align-self: stretch;
  display: table;
}

.SOS-message-box-close > p {
  display: table-cell;
  vertical-align: middle;
  cursor: pointer;
  font-weight: bold;
  padding: 5px;
  text-align: center;
}

.SOS-message-box-close > p:hover,
.SOS-message-box-close > p:focus {
  opacity: 0.5;
}

@media only screen and (min-width: 500px) {
  .SOS-img-button {
    width: 110px;
    grid-template-rows: 110px auto;
  }

  .SOS-img-button-icon {
    width: 110px;
  }

  .SOS-image {
    width: 110px;
    height: 110px;
  }

  .SOS-message-box {
    width: 70%;
  }
}

@media only screen and (min-width: 750px) {
  .SOS-img-button {
    width: 120px;
    grid-template-rows: 120px auto;
  }

  .SOS-img-button-icon {
    width: 120px;
  }

  .SOS-image {
    width: 120px;
    height: 120px;
  }

  .SOS-message-box {
    width: 60%;
  }
}

@media only screen and (min-width: 1000px) {
  .SOS-img-button {
    width: 130px;
    grid-template-rows: 130px auto;
  }

  .SOS-img-button-icon {
    width: 130px;
  }

  .SOS-image {
    width: 130px;
    height: 130px;
  }

  .SOS-message-box {
    width: 50%;
  }
}

@media only screen and (min-width: 1200px) {
  .SOS-img-button {
    width: 140px;
    grid-template-rows: 140px auto;
  }

  .SOS-img-button-icon {
    width: 140px;
  }

  .SOS-image {
    width: 140px;
    height: 140px;
  }

  .SOS-message-box {
    width: 40%;
  }
}

/*------------------------------------------------------*/
/* Animation                                            */
/*------------------------------------------------------*/

@keyframes continous-fade {
  0% { opacity: 1; }
  50% { opacity: 0.4; }
  100% { opacity: 1; }
}

@keyframes show-message {
  0% { opacity: 0; height: 0; }
  1% { opacity: 0; height: auto; }
  100% { opacity: 1; height: auto; }
}

@keyframes fade-out-message {
  0% { opacity: 1; height: auto; }
  99% { opacity: 0; height: auto; }
  100% { opacity: 0; height: 0; }
}
